import React from 'react'
import { PropTypes } from 'prop-types'
import Audio from './AudioPlayerConsumer'
import Video from './Video'

const Media = ({
  id,
  src,
  slug,
  subtitles,
  title,
  seriesTitle,
  templateKey,
  vimeo,
  thumbnail,
  upNextContent,
  onMediaDurationChange,
}) => {
  const MediaComponent = templateKey === 'Audio' ? Audio : Video

  return (
    <MediaComponent
      id={id}
      title={title}
      slug={slug}
      seriesTitle={seriesTitle}
      templateKey={templateKey}
      src={src}
      subtitles={subtitles}
      thumbnail={thumbnail}
      vimeo={vimeo}
      upNextContent={upNextContent}
      onMediaDurationChange={onMediaDurationChange}
    />
  )
}

Media.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  seriesTitle: PropTypes.string,
  slug: PropTypes.string,
  onMediaDurationChange: PropTypes.func.isRequired,
  templateKey: PropTypes.oneOf(['Audio', 'Video']).isRequired,
  thumbnail: PropTypes.shape({}),
  vimeo: PropTypes.bool,
  subtitles: PropTypes.arrayOf(
    PropTypes.shape({
      srclang: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  upNextContent: PropTypes.shape({
    previous: PropTypes.shape({ title: PropTypes.string, path: PropTypes.string }),
    next: PropTypes.shape({ title: PropTypes.string, path: PropTypes.string }),
  }),
  id: PropTypes.string.isRequired,
}

Media.defaultProps = {
  seriesTitle: null,
  thumbnail: null,
  vimeo: false,
  slug: null,
  upNextContent: null,
  // NOTE: Set to undefined here instead of null to avoid overriding the subtitles default prop
  subtitles: undefined,
}

export default Media
