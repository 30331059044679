/* eslint-disable jsx-a11y/media-has-caption */
// TODO: reason for eslint disable...solution not currently available to make subtitles default.
import React from 'react'
import GlobalContext from '../../context/GlobalContext'

const AudioPlayerConsumer = (props) => (
  <GlobalContext.Consumer>
    {({ setAudioPlayerProps }) => setAudioPlayerProps && setAudioPlayerProps(props)}
  </GlobalContext.Consumer>
)

export default AudioPlayerConsumer
