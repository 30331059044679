import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import {
  Box, Container, Typography, makeStyles,
} from '@material-ui/core'
import { graphql } from 'gatsby'
import { withTheme } from '@material-ui/styles'
import { TransitionState } from 'gatsby-plugin-transition-link'
import Lottie from 'react-lottie'
import * as animationData from '../assets/lottie/audiowave-data.json'
import { formatFileSrc } from '../utils/cms'
import GlobalContext from '../context/GlobalContext'
import Image from '../components/PreviewCompatibleImage'
import Media from '../components/media'
import Layout from '../components/Layout'
import Footer from '../components/Footer'
import ContentHeading from '../components/typography/ContentHeading'
import ContentDescription from '../components/typography/ContentDescription'
import Theme from '../components/Theme'
import RelatedContent from '../components/RelatedContent'
import EpisodeListCarousel from '../components/EpisodeListCarousel'
import NewsletterBlock from '../components/NewsletterBlock'
import AboutBlock from '../components/AboutBlock'
import PlayBtn from '../components/media/PlayBtn'

const mediaContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  width: 48,
  height: 48,
  position: 'absolute',
  top: '50%',
  marginTop: '-12px',
  left: '50%',
  marginLeft: '-12px',
}

const useStyles = makeStyles((theme) => ({
  main: {
    transition: 'all 0.4s ease',
    opacity: 0,
    minHeight: '100vh',
    [theme.breakpoints.up('lg')]: {
      // Removing transform on mobile fixes fullscreen video render on mobile
      transform: 'translate(0px,20px)',
    },
  },
  entered: {
    opacity: 1,
    [theme.breakpoints.up('lg')]: {
      // Removing transform on mobile fixes fullscreen video render on mobile
      transform: 'translate(0px,0px)',
    },
  },
  entering: {
    opacity: 0,
    transform: 'translate(0px,20px)',
  },
  exiting: { opacity: 0, transform: 'translate(0px,0px)' },
  exited: { opacity: 0, transform: 'translate(0px,0px)' },
  mediaContainer: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}))

const BaseMediaTemplate = ({
  transitionStatus,
  closeAudioPlayer,
  content: {
    frontmatter,
    fields: { slug, autoEpisodeNumber },
  },
  series: { title: seriesTitle },
  allSeries,
  previousContent,
  nextContent,
  episodeIds,
  seriesEpisodes: { edges: seriesEpisodes },
  relatedContent: { edges: relatedContent },
  theme,
  publisherData,
  url,
}) => {
  const {
    id,
    title,
    audioFile,
    templateKey,
    heroImg,
    mediaUrl,
    mediaTranscript,
    thumbnail,
    description,
    mediaDescription,
    hidePost,
  } = frontmatter

  const defaultLottieOptions = {
    loop: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  const classes = useStyles()
  const [, onMediaDurationChange] = useState(null)
  const [activeEpisodes, setActiveEpisodes] = useState(false)
  const [hasEntered, sethasEntered] = useState(false)
  const isAudio = templateKey === 'Audio'
  const seriesType = isAudio ? 'listen' : 'watch'
  const upNextContent = {
    previous: null,
    next: null,
  }

  useEffect(() => {
    if (!isAudio) closeAudioPlayer()
  }, [])

  if (previousContent) {
    upNextContent.previous = {
      ...previousContent.frontmatter,
      ...previousContent.fields,
    }
  }

  if (nextContent) {
    upNextContent.next = {
      ...nextContent.frontmatter,
      ...nextContent.fields,
    }
  }

  const newRelatedContent = relatedContent
    .map((episode) => {
      const updatedEpisode = { ...episode }
      const matchedSeries = allSeries.edges.filter(
        (seriesItem) => seriesItem.node.frontmatter.content.indexOf(
          episode.node.frontmatter.id,
        ) >= 0,
      )
      const matchingSeriesTitle = matchedSeries.length >= 1 ? matchedSeries[0].node.frontmatter.title : ''
      updatedEpisode.node.fields.seriesTitle = matchingSeriesTitle
      updatedEpisode.node.fields.seriesTitleSlug = matchedSeries.length > 0
        ? matchedSeries[0].node.fields.slug
        : ''
      return updatedEpisode
    })
    .filter(
      (episode) => episode.node.frontmatter.id !== id
        && !seriesEpisodes.some(
          (seriesEpisode) => seriesEpisode.node.id === episode.node.id,
        ),
    )
  const windowAvailable = typeof window !== 'undefined'

  if (transitionStatus === 'entered' && !hasEntered && windowAvailable) {
    sethasEntered(true)
    window.scrollTo(0, 0)
  }

  return (
    <GlobalContext.Consumer>
      {({
        globalAudioPlayerApi,
        setGlobalAudioPlayerState,
        globalAudioPlayerState: { isPlaying },
      }) => (
        <Theme>
          <Box my={{ xs: 4, md: 5 }}>
            <Container maxWidth="xl" className={classes.mediaContainer}>
              <Box css={{ position: 'relative' }}>
                {isAudio ? (
                  <>
                    <Box
                      mx="auto"
                      maxWidth={1440}
                      position="relative"
                      style={{ borderRadius: 40, overflow: 'hidden' }}
                    >
                      <Box
                        display="flex"
                        position="absolute"
                        bottom="0"
                        margin="auto"
                        left="0"
                        right="0"
                        zIndex="1"
                        width="50%"
                        style={{ transform: 'translateY(20%)' }}
                      >
                        <Lottie
                          isClickToPauseDisabled
                          options={defaultLottieOptions}
                          isPaused={!isPlaying}
                        />
                      </Box>
                      {globalAudioPlayerApi.hasAudio && (
                        <Box
                          position="absolute"
                          top="50%"
                          left="50%"
                          zIndex="2"
                          width="auto"
                          style={{ transform: 'translate(-50%, -50%)' }}
                        >
                          <PlayBtn
                            setIsPlaying={setGlobalAudioPlayerState}
                            player={globalAudioPlayerApi}
                            isPlaying={isPlaying}
                            outline
                            xl
                          />
                        </Box>
                      )}
                      <Image imageInfo={heroImg} />
                    </Box>
                    <Box style={mediaContainerStyles}>
                      <Media
                        id="plyr-globalAudioPlayer"
                        src={formatFileSrc(audioFile)}
                        thumbnail={
                          thumbnail && thumbnail.childImageSharp
                            ? thumbnail
                            : null
                        }
                        onMediaDurationChange={onMediaDurationChange}
                        title={title}
                        slug={slug}
                        seriesTitle={seriesTitle}
                        templateKey={templateKey}
                        upNextContent={upNextContent}
                        customControls
                      />
                    </Box>
                  </>
                ) : (
                  <Media
                    templateKey={templateKey}
                    id={id}
                    src={mediaUrl}
                    title={title}
                    upNextContent={upNextContent}
                    onMediaDurationChange={onMediaDurationChange}
                    vimeo
                  />
                )}
              </Box>
            </Container>
            <Box
              mt={{ xs: 3.5, lg: 4.25 }}
              mx={{ xs: 2.5, lg: 'auto' }}
              maxWidth={1064}
            >
              <ContentHeading
                data={{
                  seriesType,
                  ...frontmatter,
                  episodeNumber: autoEpisodeNumber,
                }}
                publisherData={publisherData}
                url={url}
              />
              <ContentDescription
                typeColor={
                  isAudio
                    ? theme.palette.primary.main
                    : theme.palette.primary.secondary
                }
                description={mediaDescription || description}
                transcript={mediaTranscript}
              />
            </Box>

            <Container>
              {seriesEpisodes.length > 1 ? (
                <>
                  <Box
                    textAlign="center"
                    mt={{ xs: 6, lg: 11.25 }}
                    mb={6}
                    display="flex"
                    justifyContent="center"
                  >
                    <Typography variant="h4">
                      Other Episodes In This Series
                    </Typography>
                  </Box>
                  <EpisodeListCarousel
                    currentEpisodeId={id}
                    episodeIds={episodeIds}
                    content={seriesEpisodes.filter(
                      (item) => id !== item.node.frontmatter.id,
                    )}
                    setActive={setActiveEpisodes}
                    active={activeEpisodes}
                    sorted
                    currentEpisodeNumber={autoEpisodeNumber}
                  />
                </>
              ) : null}
              {hidePost ? null : (
                <Box mt={{ xs: 6, lg: 11.25 }} id="you-might-also-like">
                  <RelatedContent content={newRelatedContent} />
                </Box>
              )}
            </Container>
          </Box>
        </Theme>
      )}
    </GlobalContext.Consumer>
  )
}

BaseMediaTemplate.propTypes = {
  transitionStatus: PropTypes.string,
  closeAudioPlayer: PropTypes.func,
  content: PropTypes.shape({
    frontmatter: {
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      templateKey: PropTypes.oneOf(['Audio', 'Video']).isRequired,
      heroImg: PropTypes.shape({
        childImageSharp: PropTypes.shape({ fluid: PropTypes.shape({}) }),
      }),
      thumbnail: PropTypes.shape({
        childImageSharp: PropTypes.shape({ fluid: PropTypes.shape({}) }),
      }),
      mediaTranscript: PropTypes.string,
      audioFile: PropTypes.shape({ publicURL: PropTypes.string.isRequired })
        .isRequired,
      mediaUrl: PropTypes.string.isRequired,
      tags: PropTypes.array,
      shareicons: PropTypes.arrayOf(
        PropTypes.shape({
          icontype: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }),
      ),
      description: PropTypes.string.isRequired,
      mediaDescription: PropTypes.string,
    },
    fields: {
      slug: PropTypes.string.isRequired,
    },
  }).isRequired,
  allSeries: PropTypes.shape({
    edges: PropTypes.arrayOf(PropTypes.shape),
  }).isRequired,
  series: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
  nextContent: PropTypes.shape({
    fields: PropTypes.shape({
      slug: PropTypes.string,
    }),
    frontmatter: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      templateKey: PropTypes.string,
      heroImg: PropTypes.shape({
        childImageSharp: PropTypes.shape({ fluid: PropTypes.shape({}) }),
      }),
      thumbnail: PropTypes.shape({
        childImageSharp: PropTypes.shape({ fluid: PropTypes.shape({}) }),
      }),
    }),
  }),
  previousContent: PropTypes.shape({
    fields: PropTypes.shape({
      slug: PropTypes.string,
    }),
    frontmatter: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      templateKey: PropTypes.string,
      heroImg: PropTypes.shape({
        childImageSharp: PropTypes.shape({ fluid: PropTypes.shape({}) }),
      }),
      thumbnail: PropTypes.shape({
        childImageSharp: PropTypes.shape({ fluid: PropTypes.shape({}) }),
      }),
    }),
  }),
  episodeIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  seriesEpisodes: PropTypes.shape({
    edges: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  relatedContent: PropTypes.shape({
    edges: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string.isRequired,
        secondary: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
  publisherData: PropTypes.shape({
    frontmatter: PropTypes.shape({
      label: PropTypes.string,
      image: PropTypes.string,
    }),
  }).isRequired,
  url: PropTypes.string.isRequired,
}

const nextContentDefault = {
  fields: {
    slug: null,
  },
  frontmatter: {
    title: null,
  },
}

BaseMediaTemplate.defaultProps = {
  transitionStatus: null,
  closeAudioPlayer: () => {},
  series: nextContentDefault,
  previousContent: nextContentDefault,
  nextContent: nextContentDefault,
  relatedContent: {
    edges: [],
  },
  seriesEpisodes: {
    edges: [],
  },
}

export const MediaTemplate = withTheme(BaseMediaTemplate)

const MediaPage = ({
  data: {
    allSeries,
    content,
    series,
    previousContent,
    nextContent,
    relatedContent,
    seriesEpisodes,
    publisherData,
  },
  pageContext: { episodeIds },
}) => {
  const {
    description,
    title,
    templateKey,
    heroImg,
    metaTitle,
    metaDescription,
    ogImage,
  } = content.frontmatter
  const classes = useStyles()
  useEffect(() => {
    window.scrollTo(0, 0)
    window.dataLayer.push({ page: null })
    window.dataLayer.push({
      event: 'page_view',
      page: {
        '@type': 'WebArticle',
        article: {
          author: publisherData.frontmatter.label,
          length: content.frontmatter.mediaDuration,
          category: content.frontmatter.templateKey,
          name: content.frontmatter.title,
          published: content.frontmatter.date,
        },
        breadcrumb: series ? `Home > ${series.frontmatter.title} > ${content.frontmatter.title}` : 'Home',
        id: content.id,
        name: content.frontmatter.title,
        page_category: 'Post',
        page_category2: series?.frontmatter.title,
        page_category3: content.frontmatter.title,
      },
    })
  }, [])
  const isAudio = templateKey === 'Audio'
  return (
    <>
      <Helmet>
        <link href="/video-js.css" rel="stylesheet" />
      </Helmet>
      <GlobalContext.Consumer>
        {({ closeAudioPlayer, setGlobalAudioPlayerState }) => (
          <Layout
            includeFooter={false}
            metaData={{
              title,
              description,
              metaTitle: metaTitle || title,
              metaDescription: metaDescription || description,
              image: ogImage
                ? ogImage.childImageSharp.fluid.src
                : heroImg.childImageSharp.fluid.src,
              slug: content.fields.slug,
              type: templateKey.toLowerCase(),
            }}
          >
            <TransitionState>
              {({ transitionStatus }) => (
                <Box
                  className={`${classes.main} ${classes[transitionStatus]}`}
                  pb={isAudio ? { xs: 4, md: 10 } : 0}
                >
                  <MediaTemplate
                    content={content}
                    setIsPlaying={setGlobalAudioPlayerState}
                    closeAudioPlayer={closeAudioPlayer}
                    transitionStatus={transitionStatus}
                    series={series?.frontmatter}
                    nextContent={nextContent}
                    previousContent={previousContent}
                    relatedContent={relatedContent}
                    allSeries={allSeries}
                    episodeIds={episodeIds}
                    seriesEpisodes={seriesEpisodes}
                    publisherData={publisherData}
                    url={content.fields.slug}
                  />
                  <AboutBlock />
                  <NewsletterBlock />
                  <Footer />
                </Box>
              )}
            </TransitionState>
          </Layout>
        )}
      </GlobalContext.Consumer>
    </>
  )
}

MediaPage.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.shape({
      id: PropTypes.string,
      fields: PropTypes.shape({
        slug: PropTypes.string,
      }),
      frontmatter: PropTypes.shape({
        date: PropTypes.string,
        templateKey: PropTypes.string,
        description: PropTypes.string,
        mediaDescription: PropTypes.string,
        title: PropTypes.string,
        tags: PropTypes.array,
        mediaDuration: PropTypes.number,
        heroImg: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              src: PropTypes.string,
            }),
          }),
        }),
        metaTitle: PropTypes.string,
        metaDescription: PropTypes.string,
        ogImage: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              src: PropTypes.string,
            }),
          }),
        }),
      }),
    }).isRequired,
    series: PropTypes.shape({
      frontmatter: PropTypes.shape({ title: PropTypes.string }),
    }).isRequired,
    allSeries: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    previousContent: PropTypes.shape({}),
    nextContent: PropTypes.shape({}),
    relatedContent: PropTypes.shape({}),
    seriesEpisodes: PropTypes.shape({}),
    publisherData: PropTypes.shape({
      frontmatter: PropTypes.shape({
        label: PropTypes.string,
        image: PropTypes.string,
      }),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    episodeIds: PropTypes.array,
  }).isRequired,
}

export const MediaPageQuery = graphql`
  query MediaById(
    $id: String!
    $seriesId: String!
    $previousId: String
    $nextId: String
    $episodeIds: [String]
    $relatedContentIds: [String]
    $publisherId: String
  ) {
    content: markdownRemark(frontmatter: { id: { eq: $id } }) {
      ...PostOrSeriesFields
      ...MediaThumbnail
      frontmatter {
        audioFile {
          publicURL
        }
        appleUrl
        spotifyUrl
        mediaDescription
        metaTitle
        metaDescription
        ogImage {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        hidePost
      }
    }
    series: markdownRemark(frontmatter: { id: { eq: $seriesId } }) {
      ...PostOrSeriesFields
    }
    allSeries: allMarkdownRemark(
      filter: {
        fields: {
          slug: { regex: "//series//" }
          content: { elemMatch: { fields: { slug: { regex: "//p//" } } } }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            content
          }
          fields {
            slug
          }
        }
      }
    }
    seriesEpisodes: allMarkdownRemark(
      filter: { frontmatter: { id: { in: $episodeIds } } }
    ) {
      edges {
        node {
          ...PostOrSeriesFields
          ...MediaThumbnail
        }
      }
    }
    previousContent: markdownRemark(frontmatter: { id: { eq: $previousId } }) {
      ...PostOrSeriesFields
      ...MediaThumbnail
    }
    nextContent: markdownRemark(frontmatter: { id: { eq: $nextId } }) {
      ...PostOrSeriesFields
      ...MediaThumbnail
    }
    relatedContent: allMarkdownRemark(
      filter: { frontmatter: { id: { in: $relatedContentIds } } }
    ) {
      edges {
        node {
          ...PostOrSeriesFields
          ...MediaThumbnail
        }
      }
    }
    publisherData: markdownRemark(frontmatter: { id: { eq: $publisherId } }) {
      frontmatter {
        label
        image
        text1
        text2
      }
    }
  }
`

export default MediaPage
