import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import {
  Box, Typography, makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  scroll: {
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: 'rgba(255,255,255,.5)',
      boxShadow: '0 0 1px rgba(255,255,255,.5)',
    },
  },
  markdown: {
    '& a': {
      color: 'rgb(255, 190, 109)',
      fontWeight: 'bold',
      textDecoration: 'none',
    },
  },
}))

const ContentDescription = ({
  typeColor, description, transcript,
}) => {
  const [contentVisible, setContentVisibility] = useState('description')
  const showDescription = contentVisible === 'description'
  const labelBorder = { borderBottom: `4px solid ${typeColor}`, transition: 'border .25s ease' }
  const cursorPointer = { cursor: 'pointer' }
  const classes = useStyles()
  return (
    <Box mt={{ xs: 2, lg: 6.25 }} maxWidth={734}>
      <Box display="flex" mb={2.5}>
        <Box
          pb={1.5}
          style={showDescription ? labelBorder : { borderBottom: '4px solid transparent' }}
        >
          <Typography
            variant="h5"
            component="h3"
            className="media-description"
            style={transcript ? cursorPointer : {}}
            onClick={transcript ? () => setContentVisibility('description') : null}
          >
            Description
          </Typography>
        </Box>
        {transcript
          ? (
            <Box
              pb={1.5}
              ml={3.5}
              style={!showDescription ? labelBorder : { borderBottom: '4px solid transparent' }}
            >
              <Typography
                variant="h5"
                component="h3"
                className="media-transcript"
                style={cursorPointer}
                onClick={() => setContentVisibility('transcript')}
              >
                Transcript
              </Typography>
            </Box>
          ) : null}
      </Box>
      <Box style={{ maxHeight: 196, overflowY: 'auto' }} className={classes.scroll}>
        <Typography component="span" variant="body1">
          {showDescription
            ? (
              <ReactMarkdown
                source={description}
                className={classes.markdown}
              />
            ) : (
              <ReactMarkdown
                source={transcript}
                className={classes.markdown}
              />
            )}
        </Typography>
      </Box>
    </Box>
  )
}

ContentDescription.propTypes = {
  typeColor: PropTypes.string,
  description: PropTypes.string.isRequired,
  transcript: PropTypes.string,
}

ContentDescription.defaultProps = {
  typeColor: '#FFF',
  transcript: null,
}

export default ContentDescription
